
import Vue, { PropType } from 'vue'
import { IProduct } from '~/types/DTO/products'
export default Vue.extend({
  name: 'RPHErrorPage',
  components: {
    Heading: () => import('~/components/atoms/Heading.vue'),
    RPHButton: () => import('rph/atoms/RPHButton/index.vue'),
    RPHSmallBannerGrid: () => import('rph/organisms/RPHSmallBannerGrid/index.vue'),
    RPHLargeBannerGrid: () => import('rph/organisms/RPHLargeBannerGrid/index.vue'),
    RPHNearestProductsGrid: () => import('rph/organisms/RPHNearestProductsGrid/index.vue'),
  },
  props: {
    status: {
      type: [Number, String],
      default: null,
    },
    products: {
      type: Array as PropType<IProduct[]>,
    },
  },
})
