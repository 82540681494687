
import Vue from 'vue'
import { IMenuObject } from '~/types/DTO/menu'

export default Vue.extend({
  name: 'RPHFooterNav',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {
    menu(): IMenuObject[] {
      return this.$store.getters['rph-menu/footer']
    },
  },
  methods: {},
})
