
import Vue from 'vue'
import Bag from 'icons/bag.svg?inline'
import ProfileRound from 'icons/profile-round.svg?inline'
import RPHBadge from 'rph/atoms/RPHBadge/index.vue'
export default Vue.extend({
  name: 'RPHHeaderProfileControl',
  components: {
    Bag,
    ProfileRound,
    RPHBadge,
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },

  async fetch() {
    if (this.$auth.loggedIn) {
      if (this.$store.state.auth.user?.id) {
        await this.$store.dispatch('rph-users/getUser', {
          id: this.$store.state.auth?.user.id,
        })
      }
      await this.$store.dispatch('rph-profile/getAddresses')
    }
  },
  fetchOnServer: false,
  computed: {
    logged(): boolean {
      // @ts-ignore
      return this.$auth.loggedIn
    },
    name(): string {
      return this.$store.state['rph-users'].user.first_name
    },
  },
  methods: {
    profileHandler() {
      if (this.logged) {
        this.$router.push('/cabinet/cabinet/profile')
      } else {
        this.$store.commit('signin/SET_SIGNIN_ACTIVE', true)
      }
    },
  },
})
