
import Vue from 'vue'

export default Vue.extend({
  name: 'RPHButton',
  props: {
    /**
     * tag of the button
     * @values button, NuxtLink, a
     */
    tag: {
      type: String,
      default: 'button',
    },
    /**
     * appearance of the button
     * @values primary, success, inline, outlined
     */
    type: {
      type: String,
      default: 'primary',
    },
    /**
     * if your button tag is NuxtLink or a, you need to provide path of your link
     */
    path: {
      type: String,
      default: '#',
    },
    /**
     * disabled state of the button
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    testId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    link() {
      return { [this.tag === 'a' ? 'href' : 'to']: this.path }
    },
  },
  methods: {
    onClickHandler(e: Event) {
      this.$emit('click', e)
    },
  },
})
