
import Vue from 'vue'
import RPHFooterNav from 'rph/molecules/RPHFooterNav/index.vue'
import RPHFooterSocials from 'rph/molecules/RPHFooterSocials/index.vue'
import RPHPayments from 'rph/molecules/RPHPayments/index.vue'

import RocketIcon from '~/assets/images/company-logo.svg?inline'

export default Vue.extend({
  name: 'RPHFooter',
  components: {
    RPHFooterNav,
    RPHFooterSocials,
    RPHPayments,
    RocketIcon,
  },
  props: {},
  data() {
    return {}
  },
  async fetch() {
    if (!this.$store.getters['rph-footer/settings'].length) {
      await this.$store.dispatch('rph-footer/getSettings')
    }
  },
  fetchOnServer: false,
  computed: {
    copyright(): string {
      return this.$store.getters['rph-footer/copyright']
    },
    workHours(): string {
      return this.$store.getters['rph-footer/workHours']
    },
  },
  methods: {},
})
