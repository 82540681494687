
import Vue from 'vue'
import Basket from 'icons/basket-cart--desktop.svg?inline'
import RPHButton from 'rph/atoms/RPHButton/index.vue'
import RPHBadge from 'rph/atoms/RPHBadge/index.vue'

export default Vue.extend({
  name: 'RPHHeaderBasket',
  components: {
    Basket,
    RPHBadge,
    RPHButton,
  },

  computed: {
    products(): any {
      return this.$store.getters['rph-basket/productsPrice']
    },
    count(): number {
      return this.$store.getters['rph-basket/totalCount']
    },
    price(): number {
      return this.$store.getters['rph-basket/totalPrice']
    },

    promoCode(): any {
      return this.$store.getters['rph-order/promoCodeDiscount']
    },
  },
})
