
import Vue from 'vue'
import Close from 'icons/close.svg?inline'
import RPHHeaderNav from 'rph/molecules/RPHHeaderNav/index.vue'
import RPHFooterSocials from 'rph/molecules/RPHFooterSocials/index.vue'
import RPHPayments from 'rph/molecules/RPHPayments/index.vue'

export default Vue.extend({
  name: 'RPHBurgerMenu',
  components: {
    Close,
    RPHHeaderNav,
    RPHFooterSocials,
    RPHPayments,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    closeHandler(e: MouseEvent) {
      this.$emit('click', e)
    },
  },
})
